.NodeBanner {
    text-align: center;
    padding: 5rem;
}

.StakingLogo {
    width: 5%;
}

.StakingTitle {
    color: #fff;
    font-size: 25px;
    font-weight: 700;
    font-family: 'Gilroy-Medium';
}

.StakingCurrency {
    color: #fff;
}

.StakingUnderline {
    background-color: #11F0B6;
    margin-top: 25px;
    height: 3px;
}

.Description {
    color: #fff;
}

.DescriptionVal {
    color: #fff;
    font-weight: bold;
}

.StakeAvax {
    margin-left: 25px;
    margin-right: 35px;
}

.How-to-stake-avax {
    background-color: #F2F2FA;
    padding: 1rem;
    margin-right: -5px !important;
    font-family:'Gilroy-Bold';
    color: black;
}

.AboutNode {
    background-color: #F2F2FA;
    padding: 20px 25px 10px 25px !important;
}

.AboutNodeImg {
    margin-top: 10px;
}

.AboutNodeTitle {
    font-size: 20px;
    margin-top: 10px;
}

.AboutNodeContent {
    white-space: nowrap;
}

.How-to-stake-avax-title {
    margin-left: -25px !important;
}

.ReadNow {
    background-color: #11F0B6 !important;
    border-color: #11F0B6 !important;
    margin-top: -30px !important;
}

.ReadNow-Font {
    color: black;
    font-family: 'Gilroy-SemiBold';
}

.Stake-Avax-via-Coindelta{
    background-color: #F2F2FA;
    padding: 1rem;
    margin-left: -3px !important;
    font-family: 'Gilroy-Bold';
    color: black;
}

.AvaxImg {
    width: 80%;
}

.AvaxDetails {
    background-color: #F2F2FA;
    padding: 3rem;
    margin-right: 20px;
    margin-left: 10px;
}

.AvaxProtocolDetails {
    font-family: 'Gilroy-Bold';
    font-size: 50px;
    line-height: 50px;
    color: #202020;
    text-align: center;
}

.AvaxProtocolDescription {
    font-family: 'Gilroy-Regular';
    font-size: 21px;
    line-height: 32px;
    color: #363636;
    margin-top: 15px;
}

.node-info ul li {
    list-style: none;
    width: 18%;
    display: inline-block;
    margin: 10px;
}

@media only screen and (max-width: 600px) {
    .node-info ul li {
        display: block;
        width: 95%;
    }   
}